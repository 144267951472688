$tooltip-background-color: #ccc;
$tooltip-text-color: $black;
$tooltip-duration: 0.4s;
$tooltip-delay: 0.2s;

// https://github.com/primer/css/blob/master/src/tooltips/tooltips.scss#L1

.tooltipped {
  position: relative;

  // This is the tooltip bubble
  &::after {
    position: absolute;
    z-index: 1000000;
    display: none;
    padding: 5px 8px;
    font-size: 0.8rem;
    -webkit-font-smoothing: subpixel-antialiased;
    color: $tooltip-text-color;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: break-word;
    white-space: pre;
    pointer-events: none;
    content: attr(aria-label);
    background: $tooltip-background-color;
    border-radius: 3px;
    opacity: 0;
  }

  // This is the tooltip arrow
  &::before {
    position: absolute;
    z-index: 1000001;
    display: none;
    width: 0;
    height: 0;
    color: $tooltip-background-color;
    pointer-events: none;
    content: '';
    border: 5px solid transparent;
    opacity: 0;
  }

  // // delay animation for tooltip
  @keyframes tooltip-appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  // This will indicate when we'll activate the tooltip
  &:hover,
  &:active,
  &:focus {
    &::before,
    &::after {
      display: inline-block;
      text-decoration: none;
      animation-name: tooltip-appear;
      animation-duration: $tooltip-duration;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
      animation-delay: $tooltip-delay;
    }
  }
}

// .tooltipped-no-delay:hover,
// .tooltipped-no-delay:active,
// .tooltipped-no-delay:focus {
//   &::before,
//   &::after {
//     opacity: 1;
//     animation: none;
//   }
// }

// Tooltipped to the left
.tooltipped-w {
  &::after {
    right: 100%;
    bottom: 50%;
    margin-right: 6px;
    transform: translateY(50%);
  }

  &::before {
    top: 50%;
    bottom: 50%;
    left: -7px;
    margin-top: -6px;
    border-left-color: $tooltip-background-color;
  }
}
