$callout-border-width: 3px;

.callout {
  padding-bottom: 1rem;

  ul,
  ol,
  dl {
    margin-bottom: 0.3rem;
    margin-left: 1.25rem;
  }

  .title {
    color: $black;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &:not(.nuxeo) {
    ul,
    ol,
    dl {
      list-style: none;
      margin-left: 0;

      li {
        margin-top: 0.7rem;
      }
    }

    h3 {
      color: $black;
      font-size: 1.25rem;
    }
  }

  &.primary {
    background-color: lighten($primary-color, 41%);
  }

  &.secondary {
    background-color: #f2f2f3;
    padding: 2rem 1rem;
  }

  &.nuxeo {
    background-color: $white;
    border: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 1.5rem;
    position: relative;

    .fa {
      //-stack
      font-size: 1.4rem;
      left: 1rem;
      position: absolute;
    }

    &.success,
    &.tip {
      border-left: $callout-border-width solid $success-color;
    }

    &.hint,
    &.info {
      border-left: $callout-border-width solid $primary-color;
    }

    &.note {
      border-left: $callout-border-width solid $warning-color;

      > div {
        &:first-of-type {
          &::before {
            color: $warning-color;
            content: 'Note';
          }
        }
      }
    }

    &.warning {
      background-color: transparent;
      border-left: $callout-border-width solid $alert-color;

      > div {
        &:first-of-type {
          &::before {
            color: $alert-color;
            content: 'Warning';
          }
        }
      }
    }

    > div {
      &:first-of-type {
        &::before {
          font-size: $nuxeo-body-small;
          margin-right: 1.5rem;
          text-transform: uppercase;
        }
      }
    }

    &.tip {
      > div {
        &:first-of-type {
          &::before {
            color: $success-color;
            content: 'Tip';
          }
        }
      }
    }

    &.info {
      > div {
        &:first-of-type {
          &::before {
            color: $primary-color;
            content: 'Info';
          }
        }
      }
    }

    .heading {
      font-size: $nuxeo-body-small;
      text-transform: uppercase;
    }

    p {
      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}
