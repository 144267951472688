$scrollbar-color: rgba($black, 0.3);
$menu-fg: rgba($nuxeo-color-dark, 0.8);
$menu-fg-active: $nuxeo-color-dark;

// Left menu
@mixin menu-section-colours($color) {
  $menu-hover: lighten($color, 20%);
  $level-0-color: lighten($color, $nuxeo-section-lighten);
  // $level-1-color: lighten($color, $nuxeo-section-lighten);

  //
  // #side_menu_container #side_menu .input-container {
  //   background: $level-0-color;
  // }

  #side_menu ul {
    li {
      &.l1 {
        background-color: $level-0-color;
      }

      // // &.l2 {
      // //   background-color: $level-1-color;
      // // }
      //
      // &:hover {
      //   background-color: $menu-hover;
      // }
      //
      // .toc-item,
      // .fa,
      // &.contains-toc {
      //   background: none;
      // }
    }
  }

  .breadcrumb__current {
    color: $color;
  }
}

.doc-section {
  &.doc-section- {
    @include menu-section-colours($nuxeo-user-documentation-color);
  }

  &.doc-section-tutorials {
    @include menu-section-colours($nuxeo-tutorials-color);
  }

  &.doc-section-server {
    @include menu-section-colours($nuxeo-server-color);
  }

  &.doc-section-web-ui {
    @include menu-section-colours($nuxeo-web-ui-color);
  }

  &.doc-section-dam {
    @include menu-section-colours($nuxeo-dam-color);
  }

  &.doc-section-desktop-mob {
    @include menu-section-colours($nuxeo-desktop-mob-color);
  }

  &.doc-section-addons {
    @include menu-section-colours($nuxeo-addons-color);
  }

  &.doc-section-user-documentation {
    @include menu-section-colours($nuxeo-user-documentation-color);
  }

  &.doc-section-sdks {
    @include menu-section-colours($nuxeo-sdks-color);
  }

  &.doc-section-services {
    @include menu-section-colours($nuxeo-services-color);
  }

  &.doc-section-tools {
    @include menu-section-colours($nuxeo-tools-color);
  }
}

#content_container {
  min-height: calc(100vh - #{$header-height});
}

#side_menu_container {
  background: $nuxeo-menu-bg;
  max-height: calc(100vh - #{$header-height});
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
  }
}

// Filtering input
#side_menu {
  height: calc(100vh - #{$header-height});

  .input-container {
    margin: 0;
    padding: 1rem 1.25rem;
  }

  .input-group {
    margin: 0.6rem 0;
    width: 100%;
  }

  .input-group-field {
    border-bottom: 1px solid $c-black;
    color: $menu-fg-active;
    font-size: 0.75rem;
    padding: 0;
    height: auto;
    // font-size: 0.9rem;
    // height: 2rem;

    &::placeholder {
      color: $menu-fg;
      font-size: 0.75rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

// Small
@media screen and #{breakpoint(small down)} {
  #side_menu {
    max-width: none;
  }
}

// Menu
#side_menu {
  ul {
    list-style: none;
    margin: 0;
  }

  li {
    font-size: 14px;
    text-transform: capitalize;
    transition: background-color 0.2s ease;

    i.fa {
      cursor: pointer;
      float: right;
      margin-right: 0.4em;
      margin-top: 0.5em;
      transition: transform 0.2s ease;
    }

    &.open i.fa {
      transform: rotate(90deg);
    }

    a {
      color: $menu-fg;
      display: block;
      padding: 0.2rem 1.5rem 0.2rem 0;
      transition: color 0.2s ease;
    }

    &.active a {
      color: $menu-fg-active;
    }

    .highlight {
      color: $primary-color;
    }

    &.l1 {
      padding-left: 1.25rem;
      font-weight: $global-weight-bold;
      text-transform: uppercase;
    }

    &.l2,
    &.l3,
    &.l4,
    &.l5,
    &.l6,
    &.l7 {
      &:hover,
      &.active {
        background-color: $c-grey-0;
      }

      &.active {
        font-weight: $global-weight-bold;
      }
    }

    &.l2 {
      padding-left: 1.25rem;
      margin: 0.8rem 0;
    }

    // &.l3,
    // &.l4,
    // &.l5,
    // &.l6,
    // &.l7 {
    //   background-image: url('data:image/svg+xml,<svg stroke="%23999" stroke-linejoin="round" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 18 13"><path d="M0 4L8 12L16 4L8 12z"/></svg>');
    //   background-position: left;
    //   background-size: 100% 1rem;
    // }

    &.l3 {
      padding-left: 2.25rem;
    }

    &.l4 {
      padding-left: 3.25rem;
    }

    &.l5 {
      padding-left: 4.25rem;
    }

    &.l6 {
      padding-left: 5.25rem;
    }

    &.l7 {
      padding-left: 6.25rem;
    }
  }
}
