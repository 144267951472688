$content-background: $white;

.max-900 {
  max-width: 900px;
}

.collapse {
  padding: 0;
}

.dl-boxes {
  display: flex;
  flex-wrap: wrap;
  font-size: 200%;
  justify-content: space-around;
  text-align: center;

  > a {
    background: #f2f2f3;
    color: $c-black;
    margin-bottom: 2rem;
    max-width: 200px;
    min-width: 150px;
    padding: 20px 0;
    transition: background 0.2s ease, color 0.2s ease;

    &:hover {
      color: $c-blue;
      background: darken(#f2f2f3, 5%);
    }
  }
}

@media screen and #{breakpoint(small down)} {
  .background-for-medium {
    background-image: none !important;
  }

  .small-text-center-only {
    text-align: center;
  }
}

@media screen and #{breakpoint(medium down)} {
  .background-for-large {
    background-image: none !important;
  }
}

@media screen and #{breakpoint(medium up)} {
  .content-gutter {
    margin-left: 1.5rem;
  }

  #content {
    // Remove padding on boxes
    > .row > .column {
      &.medium-6 {
        &:nth-of-type(odd) {
          padding-left: 0;
        }

        &:nth-of-type(even) {
          padding-right: 0;
        }
      }
    }
  }
}

// large+
@media screen and #{breakpoint(large up)} {
  .content-gutter {
    margin-left: 5.5rem;
  }

  #content {
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > div.nuxeo.callout {
      max-width: 40rem;
    }

    > ol > li,
    > ul > li {
      & div,
      & p {
        max-width: 37em;
      }
    }

    > pre,
    > table,
    > .table-scroll {
      max-width: 100%;
    }
  }
}

.accordion {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .accordion-title {
    border-top-left-radius: 0.43rem;
    border-top-right-radius: 0.43rem;
    font-weight: $global-weight-bold;
  }
}

.separator {
  border-bottom: 1px solid $medium-gray;
}

.heading-group {
  margin-bottom: 1.5rem;

  h1 {
    margin-bottom: 0.5rem;
  }

  h1 ~ div {
    color: $medium-gray;
  }

  div > a {
    color: $medium-gray;
    margin-left: 0.5rem;

    .fa {
      font-size: 1.2rem;
    }
  }
}

footer {
  padding-bottom: 6rem;
  padding-top: 2rem;
}

// Images
.inline {
  display: inline-block;
}

img {
  &.nuxeo {
    &.border {
      border: 1px solid $medium-gray;
    }

    &.thumbnail {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
}

.flex-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  justify-content: center;
}
.flex-box {
  max-width: calc(33.3% - 0.7rem);
  min-width: 174px;
}
