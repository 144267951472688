.doc-tag {
  background-color: transparent;
  border: 1px solid $c-black;
  border-radius: 4px;
  color: $c-black;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  margin-left: 0.5em;
  padding: 0 0.25em;
  text-transform: uppercase;
  vertical-align: middle;

  &--new {
    background-color: $c-blue;
    border-color: $c-blue;
    color: $c-white;
  }

  .pnuxeo-server & {
    font-size: 0.75rem;
    letter-spacing: 0;
  }
}
