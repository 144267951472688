// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 100;
//   src: url('https://rsms.me/inter/font-files/Inter-Thin.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-Thin.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 100;
//   src: url('https://rsms.me/inter/font-files/Inter-ThinItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-ThinItalic.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 200;
//   src: url('https://rsms.me/inter/font-files/Inter-ExtraLight.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-ExtraLight.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 200;
//   src: url('https://rsms.me/inter/font-files/Inter-ExtraLightItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-ExtraLightItalic.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 300;
//   src: url('https://rsms.me/inter/font-files/Inter-Light.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-Light.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 300;
//   src: url('https://rsms.me/inter/font-files/Inter-LightItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-LightItalic.woff?v=3.15') format('woff');
// }

@font-face {
  font-display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.15') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Regular.woff?v=3.15') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('https://rsms.me/inter/font-files/Inter-Italic.woff2?v=3.15') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Italic.woff?v=3.15') format('woff');
}

// @font-face {
//   font-display: block;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 500;
//   src: url('https://rsms.me/inter/font-files/Inter-Medium.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-Medium.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 500;
//   src: url('https://rsms.me/inter/font-files/Inter-MediumItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-MediumItalic.woff?v=3.15') format('woff');
// }

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('https://rsms.me/inter/font-files/Inter-SemiBold.woff2?v=3.15') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-SemiBold.woff?v=3.15') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('https://rsms.me/inter/font-files/Inter-SemiBoldItalic.woff2?v=3.15') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-SemiBoldItalic.woff?v=3.15') format('woff');
}

// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 700;
//   src: url('https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-Bold.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 700;
//   src: url('https://rsms.me/inter/font-files/Inter-BoldItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-BoldItalic.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 800;
//   src: url('https://rsms.me/inter/font-files/Inter-ExtraBold.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-ExtraBold.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 800;
//   src: url('https://rsms.me/inter/font-files/Inter-ExtraBoldItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-ExtraBoldItalic.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 900;
//   src: url('https://rsms.me/inter/font-files/Inter-Black.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-Black.woff?v=3.15') format('woff');
// }
//
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 900;
//   src: url('https://rsms.me/inter/font-files/Inter-BlackItalic.woff2?v=3.15') format('woff2'),
//     url('https://rsms.me/inter/font-files/Inter-BlackItalic.woff?v=3.15') format('woff');
// }

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-display: swap;
  font-family: 'Inter var';
  font-named-instance: 'Regular';
  font-style: normal;
  font-weight: 100 900;
  src: url('https://rsms.me/inter/font-files/Inter-roman.var.woff2?v=3.15') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter var';
  font-named-instance: 'Italic';
  font-style: italic;
  font-weight: 100 900;
  src: url('https://rsms.me/inter/font-files/Inter-italic.var.woff2?v=3.15') format('woff2');
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: 'slnt' 0deg }
  .italic { font-variation-settings: 'slnt' 10deg }

  @font-face {
  font-display: swap;
  font-family: 'Inter var experimental';
  font-style: oblique 0deg 10deg;
  font-weight: 100 900;
  src: url('https://rsms.me/inter/font-files/Inter.var.woff2?v=3.15') format('woff2');
}
*/
