.tabs {
  border: 0;
  font-weight: $global-weight-bold;
  letter-spacing: .05rem;
  margin-bottom: 3rem;
  text-transform: uppercase;

  li {
    border-bottom: 2px solid $secondary-color;
    border-top: 1px solid transparent; // To counterbalance when tab is active
    white-space: nowrap;

    // To prevent tabs from stacking
    &:first-child {
      &:nth-last-child(2),
      ~ li {
        max-width: calc(100% / 2);
      }

      &:nth-last-child(3),
      ~ li {
        max-width: calc(100% / 3);
      }

      // Assuming up to 4 tabs
      &:nth-last-child(4),
      ~ li {
        max-width: calc(100% / 4);
      }
    }

    a {
      color: $medium-gray;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .is-active {
    border: 2px solid $secondary-color;
    border-bottom: 0;
    border-top: 3px solid $primary-color;

    a {
      color: $secondary-color;
    }
  }
}

.tabs-content {
  border: 0;
}
