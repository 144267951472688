$versions-inline-fg: rgba(0, 5, 34, 0.6);
$versions-bg: rgba(255, 255, 255, 0.2);

#page-versions {
  background-color: $versions-bg;
  border-radius: 5rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 19px;
  margin-left: 1rem;
  padding: 0.5em 1em 0.4em 0;
  text-transform: uppercase;

  .version-label {
    border-bottom: 1px solid $c-black;
  }

  > ul {
    height: inherit;

    > li {
      line-height: normal;

      > a {
        color: $black;
        margin-left: 0;
        padding-bottom: 3px;
        padding-left: 0;
        padding-top: 3px;

        &::after {
          display: none;
        }
      }
    }
  }

  .menu .submenu {
    border: 0;
    margin-top: 0.3em;
    z-index: 2;

    li {
      background: $white;
      box-shadow: 0 4px 4px 0 $nuxeo-overview-shaddow;
    }

    a {
      color: $versions-inline-fg;

      &.active {
        color: $black;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }
}

#docs_footer {
  #versions_inline {
    background-color: $c_blue;

    li {
      color: $white;
      font-stretch: normal;
      font-style: normal;
    }

    .version-label {
      background: transparent;
      height: 50px;
      line-height: 50px;
      padding-left: 2rem;
      text-transform: uppercase;

      .vertical-bar {
        border-right: 2px solid $white;
        display: inline-block;
        height: 1em;
        margin-left: 1em;
      }
    }

    a {
      color: $white;
      line-height: 50px;
      padding: 0 0.2em 0 3em;

      &.active {
        font-weight: 600;
      }

      &:hover {
        color: $c_green;
      }
    }
  }
}
