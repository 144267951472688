// Inline code
code {
  background: #61aeee4f;
  color: $c-black;
  overflow-wrap: break-word;
  position: relative;
  z-index: 1;

  a > & {
    border: 1px solid;
  }
}

// Code block
pre {
  background: $nuxeo-color-code-background;
  color: $nuxeo-color-code-foreground;
  font-family: 'Courier 10 Pitch', Courier, monospace;
  font-size: 15px !important;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  position: relative;
  white-space: pre;
  z-index: 1;

  .copy-area {
    position: absolute;
    right: 0;
    top: 0;
  }

  .copy-button {
    font-family: $body-font-family;
    background-color: lighten($nuxeo-color-code-border, 15%);
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    cursor: pointer;
    margin: 4px;
    opacity: 0;
    padding: 4px 6px;
    transition: opacity 0.4s ease;

    &:hover {
      background-color: $nuxeo-color-code-border;
    }
  }

  &:hover .copy-button {
    opacity: 1;
  }

  > code {
    background: transparent;
    border: 0;
    color: $nuxeo-color-code-foreground;
    display: contents;

    > code {
      background: transparent;
    }
  }
}

// Code panel
.code-heading {
  color: $header-color;
  font-family: $header-font-family;
  font-style: $header-font-style;
  font-weight: $header-font-weight;
  line-height: $header-lineheight;
  margin-bottom: -1rem;
  text-rendering: $header-text-rendering;

  p & {
    margin: 0;
  }

  // Ignore empty paragraphs added by Markdown
  ~ p:blank,
  ~ p:empty {
    display: none;
  }
}

// code-header - Same as h4
@each $size, $headers in $header-styles {
  @include breakpoint($size) {
    .code-heading {
      font-size: rem-calc(map-get(map-get($headers, 'h4'), font-size));
    }
  }
}
