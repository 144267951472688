#menu-search-icon {
  cursor: pointer;
  padding: 0 1rem;
  transition: color 0.2s ease;

  &:hover {
    color: $c-green;
  }
}

#search-box {
  border: 0;
  display: flex;
  height: 50px;
  margin: 0;
  overflow: hidden;
  right: 0;
  top: 0;
  transition: width 0.4s ease-in;
  width: 0;

  &.active {
    width: 23rem;
    transition: width 0.4s ease-out;
  }

  svg {
    fill: $c-white;
    width: 12px;
    height: 12px;
  }

  input {
    border-bottom: 1px solid $c-white;
    color: $c-white;
    display: inline;
    font-family: $font-primary;
    font-style: normal;
    // margin: 1.2rem 0 0 1rem;
    margin: 0 0 0 2rem;
    max-width: none;
    outline: none;
    padding: 0;
    width: 21rem;
    height: 1.25em;

    &::placeholder {
      color: $c-grey-2;
      font-style: normal;
    }
  }

  button {
    background: transparent;
    border: 0 !important;
    border-radius: 0;
    color: $c-blue;
    margin: 0;
    min-width: unset;
    padding: 0.7rem;
    transition: color 0.2s ease;

    &.ais-SearchBox-submit {
      display: none;
    }

    &:hover {
      color: $white;
    }

    .svg-icon {
      vertical-align: sub;
    }
  }
}

.search-input {
  .ais-SearchBox-input {
    font-style: italic;
    margin: 0;
    outline: none;
    width: calc(100% - 20px);

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  .ais-SearchBox {
    position: relative;

    .ais-SearchBox-reset {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 7px;

      &:hover {
        color: $c-green;
      }
    }
  }
}

.ais-Hits {
  color: $c-black;
  line-height: 1.2;
  font-weight: $weight-roman;

  &.ais-Hits--empty {
    padding: 1rem 2rem;
  }
}

.ais-Hits-list {
  list-style: none;
  margin: 0;
}

.ais-Hits-item:hover {
  mark {
    color: $c-blue;
  }
}

#algolia_search_results {
  background: $white;
  border: 1px solid $c-grey-3;
  // border-radius: 4px;
  margin: 16px 8px 0;
  position: absolute;
  right: 0;
  top: 50px;
  width: 100%;
  max-width: 500px;
  z-index: 50;

  &::before {
    background: $white;
    // border-radius: 2px;
    border-right: 1px solid $c-grey-3;
    border-top: 1px solid $c-grey-3;
    content: '';
    display: block;
    height: 14px;
    position: absolute;
    right: 50px;
    top: -8px;
    transform: rotate(-45deg);
    width: 14px;
    z-index: 2;
  }

  .scrollable {
    max-height: calc(80vh - 100px);
    overflow-y: auto;
  }

  a {
    color: $c-black;
    display: block;
    padding: 0.5rem 1rem;
    transition: color 0.2s ease;

    &:hover {
      color: $c-blue;
    }
  }
}
