body {
  font-family: $font-primary;
  font-weight: $weight-roman;
  line-height: 1.5;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: $font-primary-var;
  }
}

.heading-anchor {
  h1 & {
    top: 86px;
  }

  h2 & {
    top: 81px;
  }

  h3 & {
    top: 77px;
  }

  h4 & {
    top: 72px;
  }

  h5 & {
    top: 70px;
  }

  h6 & {
    top: 69px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: relative;
    width: 0;
    height: 70px;
    margin-top: -70px;
  }

  .heading-anchor {
    color: transparent;
    font-size: 1.3rem;
    left: -1.7rem;
    padding-right: 2rem;
    position: absolute;
  }

  &:hover .heading-anchor {
    color: $c-grey-2;
  }

  .callout &:hover .heading-anchor {
    color: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: $global-weight-bold;
}

a {
  transition: color 0.2s ease;
  word-break: break-word;
  position: relative;
  z-index: 1;

  &:hover {
    color: darken($primary-color, 15%);
  }
}

table {
  border: 2px solid $medium-gray;
  margin-bottom: 1.5em;

  th {
    text-align: left;
  }

  td {
    font-size: 0.875rem; // 14
    vertical-align: top;
  }
}

ul,
ol {
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
  margin-after: 0;
  margin-before: 0;
  margin-bottom: 1.5em;
  padding-start: 0;

  ol {
    list-style-type: lower-alpha;

    ol {
      list-style-type: decimal;
    }
  }
}

.sequence-link {
  font-weight: $global-weight-bold;
  padding-bottom: 1rem;
  margin-top: 2rem;
}
