/*

Based upon:
Atom One by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax

*/
@mixin highlightjs {
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: $mono-1;
    background: $base;

    &-comment,
    &-quote {
      color: $mono-3;
      font-style: italic;
    }

    &-doctag,
    &-keyword,
    &-formula {
      color: $hue-3;
    }

    &-section,
    &-name,
    &-selector-tag,
    &-deletion,
    &-subst {
      color: $hue-5;
    }

    &-literal {
      color: $hue-1;
    }

    &-string,
    &-regexp,
    &-addition,
    &-attribute,
    &-meta-string {
      color: $hue-4;
    }

    &-built_in,
    &-class &-title {
      color: $hue-6-2;
    }

    &-attr,
    &-variable,
    &-template-variable,
    &-type,
    &-selector-class,
    &-selector-attr,
    &-selector-pseudo,
    &-number {
      color: $hue-6;
    }

    &-symbol,
    &-bullet,
    &-link,
    &-meta,
    &-selector-id,
    &-title {
      color: $hue-2;
    }

    &-emphasis {
      font-style: italic;
    }

    &-strong {
      font-weight: $global-weight-bold;
    }

    &-link {
      text-decoration: underline;
    }
  }
}

.dark-theme {
  // Light syntax highlighting
  $base: #fafafa;
  $mono-1: #383a42;
  // $mono-2:   #686b77;
  $mono-3: #a0a1a7;
  $hue-1: #0184bb;
  $hue-2: #4078f2;
  $hue-3: #a626a4;
  $hue-4: #50a14f;
  $hue-5: #e45649;
  // $hue-5-2:  #c91243;
  $hue-6: #986801;
  $hue-6-2: #c18401;

  // @include highlightjs;
}

// Standard Dark syntax highlighting
$base: $nuxeo-color-code-background;
$mono-1: $nuxeo-color-code-foreground;
// $mono-2:   #818896;
$mono-3: #5c6370;
$hue-1: #56b6c2;
$hue-2: #61aeee;
$hue-3: #c678dd;
$hue-4: #98c379;
$hue-5: #e06c75;
// $hue-5-2:  #be5046;
$hue-6: #d19a66;
$hue-6-2: #e6c07b;

@include highlightjs;
