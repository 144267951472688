.toc__heading {
  color: #7f8284;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

#toc nav {
  padding-bottom: 3rem;
}

#toc_list {
  max-width: 300px;
  max-height: calc(100vh - 15rem);
  overflow-x: hidden;
  overflow-y: auto;

  .toc-item {
    background-color: transparent;
    font-size: 0.875rem;
    padding-left: 0;
    text-transform: capitalize;

    a {
      color: $menu-fg-active;
      font-weight: normal;
      padding: 0.3rem 0;
    }

    &:hover a {
      color: $primary-color;
    }

    & a.is-active {
      font-weight: $global-weight-bold;
    }

    // TOC levels
    &.h2 {
      border-bottom: 1px solid $c-grey-2;
      padding-bottom: 0.3rem;
      margin-bottom: 0.3rem;
    }

    &.h3 {
      padding-left: 1rem;
    }

    &.h4 {
      padding-left: 2rem;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}
